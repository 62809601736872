import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const d = {};

d.initFirebase = (cb) => {
  if (process.env.REACT_APP_API_KEY) {
    firebase.initializeApp(config);
    console.log('firebase app key set');
    return cb(null);
  } else {
    fetch('/__/firebase/init.json').then((res) => {
      res.json().then((j) => {
        j.authDomain = 'rehull.com';
        console.log('rehull.com');
        firebase.initializeApp(j);
        return cb(null);
      }).catch((err) => {
        console.log(err);
      })
    }).catch((err) => {
      console.log(err);
      return cb(err);
    });
  }
};

d.loginWithGoogle = () => {
  let provider = new firebase.auth.GoogleAuthProvider()
  firebase.auth().signInWithPopup(provider).then((res) => {
    // This gives you a Google Access Token.
    // You can use it to access the Google API.
    // let token = result.credential.accessToken;
    // The signed-in user info.
    // let user = result.user;
    console.log(res);
  }).catch((err) => {
    /* Handle Errors here.
    let errorCode = error.code;
    let errorMessage = error.message;
    // The email of the user's account used.
    let email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    let credential = error.credential;
    // ...
    */
    console.log(err);
  });
};

d.loginWithGoogleRedirect = () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  firebase.auth().signInWithRedirect(provider);
};

d.getRedirectResult = () => {
  firebase.auth().getRedirectResult().then((res) => {
    if (res.credential) {
      let token = res.credential.accessToken;
      console.log(token);
    }
    // The signed-in user info.
    let user = res.user;
    console.log(user);
  }).catch((err) => {
    // Handle Errors here.
    let errorCode = err.code;
    let errorMessage = err.message;
    // The email of the user's account used.
    let email = err.email;
    // The firebase.auth.AuthCredential type that was used.
    let credential = err.credential;
  });
};

export default d;
