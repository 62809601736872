import React from 'react';
import Button from '@material-ui/core/Button';
import utils from './utils';
import logo from './img/logo.svg';
import googleLogo from './img/btn_google_web.png';
import enso from './img/enso.svg';
import './App.css';

const handleClick = () => {
  utils.loginWithGoogleRedirect();
};

const FirebaseScripts = () => {
  if (process.env.REACT_APP_API_KEY) {
    return <></>;
  }
  return (
    <>
      {`
        <script src="/__/firebase/7.11.0/firebase-app.js"></script>
        <script src="/__/firebase/7.11.0/firebase-analytics.js"></script>
        <script src="/__/firebase/7.11.0/firebase-auth.js"></script>
        <script src="/__/firebase/7.11.0/firebase-firestore.js"></script>
      `}
    </>
  );
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={enso} style={{width: 400, height: 'auto'}}/>
        <p>
        <Button
        color="primary"
        onClick={handleClick}>
          <img width="200px" src={googleLogo} />
        </Button>
        </p>
      </header>
    </div>
  );
}

export default App;
